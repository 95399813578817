import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from "@material-ui/core";
import { EmptyList } from "src/apps/commons/dataDisplays";

interface StyleProps {
  searchHeight?: number;
}
const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 360,
    maxHeight: (props: StyleProps) =>
      `calc(100vh - ${170 + (props.searchHeight ?? 0)}px)`
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  emptyStateTD: {
    maxHeight: "calc(100vh - 400px)",
    padding: theme.spacing(8, 4),
    borderBottom: 0
  }
}));

type Order = "asc" | "desc";

type BaseColumnType<T> = {
  label: React.ReactNode;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: "left" | "center" | "right";
  renderCell: (data: T, isLoading: boolean) => React.ReactElement | string;
  renderFaded?: (data: T) => boolean;
  style?: React.CSSProperties;
};

type OrderableColumnType<T, OptionKeyTypes> = BaseColumnType<T> & {
  id: OptionKeyTypes;
  orderable?: boolean;
};

type UnOrderableColumnType<T, AdditionalOptionKeyTypes> = BaseColumnType<T> & {
  id: AdditionalOptionKeyTypes;
};

export type ColumnTypes<T, OptionKeyTypes, AdditionalOptionKeyTypes> =
  | OrderableColumnType<T, OptionKeyTypes>
  | UnOrderableColumnType<T, AdditionalOptionKeyTypes>;

interface Props<T, OptionKeyTypes, AdditionalOptionKeyTypes>
  extends StyleProps {
  isLoading: boolean;
  columns: ColumnTypes<T, OptionKeyTypes, AdditionalOptionKeyTypes>[];
  datas: T[];
  page: number;
  pageSize: number;
  totalCount: number;
  orderDesc?: boolean;
  orderBy?: OptionKeyTypes;
  handleChangePageProps: (page: number, rowsPerPage: number) => void;
  handleSort: (key: OptionKeyTypes) => void;

  selectable?: boolean;
  itemSelected?: (item: T) => boolean;
  itemSelectable?: (item: T) => boolean;
  onSelectionChanged?: (item: T, selected: boolean) => void;
}

function renderColumnLabel(label: React.ReactNode) {
  if (typeof label !== "string") return label;

  const lines = label.split("\n");
  if (lines.length === 1) {
    return lines[0];
  }
  return (
    <>
      {lines.map((line, i) => (
        <React.Fragment key={i}>
          {i > 0 && <br />}
          {line}
        </React.Fragment>
      ))}
    </>
  );
}

export default function TableContainers<
  T extends { id: string },
  OptionKeyTypes extends string,
  AdditionalOptionKeyTypes extends string
>(props: Props<T, OptionKeyTypes, AdditionalOptionKeyTypes>) {
  const {
    isLoading,
    columns,
    datas,
    page,
    pageSize,
    totalCount,
    orderDesc,
    orderBy,
    handleSort,
    selectable,
    itemSelected,
    itemSelectable,
    onSelectionChanged,
    ...styleProps
  } = props;
  const order: Order = orderDesc ? "desc" : "asc";
  const classes = useStyles(styleProps);

  const handleChangePage = (_event: unknown, newPage: number) => {
    props.handleChangePageProps(newPage + 1, pageSize);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const changedRowsPerPage = +event.target.value;
    props.handleChangePageProps(1, changedRowsPerPage);
  };

  const createSortHandler =
    (key: OptionKeyTypes) => (_: React.MouseEvent<unknown>) => {
      handleSort(key);
    };

  const cellStyleFromColumn = (
    column: ColumnTypes<T, OptionKeyTypes, AdditionalOptionKeyTypes>
  ): React.CSSProperties => ({
    ...column.style,
    minWidth: column.minWidth ?? column.width,
    maxWidth: column.maxWidth ?? column.width,
    width: column.width
  });

  const hasManageCol = columns[0].id === "MANAGE";

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {selectable && !hasManageCol && (
                <TableCell style={{ width: 52 }} />
              )}
              {columns.map(column => {
                const orderable = "orderable" in column && column.orderable;

                if (orderable) {
                  const orderableColumn = column as OrderableColumnType<
                    T,
                    OptionKeyTypes
                  >;
                  return (
                    <TableCell
                      key={orderableColumn.id}
                      align={orderableColumn.align}
                      style={cellStyleFromColumn(column)}
                    >
                      <TableSortLabel
                        active={orderBy === orderableColumn.id}
                        direction={
                          orderBy === orderableColumn.id ? order : "asc"
                        }
                        onClick={createSortHandler(orderableColumn.id)}
                      >
                        {renderColumnLabel(orderableColumn.label)}
                      </TableSortLabel>
                    </TableCell>
                  );
                } else {
                  const mergeCheckboxCol =
                    hasManageCol && selectable && column.id === "MANAGE";
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={cellStyleFromColumn(column)}
                      colSpan={mergeCheckboxCol ? 2 : undefined}
                    >
                      {renderColumnLabel(column.label)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
            {isLoading && (
              <TableRow key="loading_header">
                <TableCell
                  colSpan={columns.length + (selectable ? 1 : 0)}
                  style={{ height: "0px", padding: 0, border: 0 }}
                >
                  <LinearProgress style={{ marginTop: "-4px" }} />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody style={{ opacity: isLoading ? 0.5 : 1 }}>
            {datas.map(data => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                  {selectable && (
                    <TableCell
                      align="center"
                      style={{ padding: "0 0 0 8px", width: 52 }}
                    >
                      <Checkbox
                        color="primary"
                        checked={itemSelected?.(data) ?? false}
                        disabled={
                          itemSelectable ? !itemSelectable(data) : false
                        }
                        onChange={ev =>
                          onSelectionChanged?.(data, ev.target.checked)
                        }
                      />
                    </TableCell>
                  )}
                  {columns.map(column => {
                    const faded = column.renderFaded?.(data) === true;
                    const style = cellStyleFromColumn(column);
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={faded ? { ...style, opacity: 0.6 } : style}
                      >
                        {column.renderCell(data, isLoading)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}

            {!isLoading && datas.length === 0 && (
              <TableRow tabIndex={-1} key="table_empty_state">
                <TableCell
                  align="center"
                  colSpan={columns.length + (selectable ? 1 : 0)}
                  className={classes.emptyStateTD}
                >
                  <EmptyList text="ไม่พบข้อมูล" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={pageSize}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
