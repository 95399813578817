import React from "react";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as BlankCheckBoxIcon
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

const ActiveStatus = (props: { value: boolean; tooltip?: string }) => {
  const { value, tooltip } = props;
  const icon = value ? (
    <CheckBoxIcon key={"Y"} fontSize="small" color="secondary" />
  ) : (
    <BlankCheckBoxIcon key={"N"} fontSize="small" color="disabled" />
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top" key={tooltip}>
        {icon}
      </Tooltip>
    );
  }
  return icon;
};

export default ActiveStatus;
