import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

const useStyles = makeStyles(_ => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  icon: {
    fontSize: "6rem",
    color: "rgba(197, 195, 193, 0.46)"
  },
  text: {
    color: "rgba(197, 195, 193, 0.46)"
  }
}));

interface Props {
  text?: string;
}

const EmptyList: React.FC<Props> = props => {
  const { text } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <HomeOutlinedIcon fontSize="large" className={classes.icon} />
      <Typography variant="h1" className={classes.text}>
        {text ?? "Nothing in List"}
      </Typography>
    </div>
  );
};

export default EmptyList;
