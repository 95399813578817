import React from "react";
import { Divider, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    textAlign: "left"
  },
  marginTop: {
    marginTop: theme.spacing(2)
  }
}));

type Props = {
  title: string;
  marginTop?: boolean;
};

const SubHeader = (props: Props) => {
  const classes = useStyles();
  const { title, marginTop } = props;

  return (
    <div className={marginTop ? classes.marginTop : undefined}>
      <Typography className={classes.subHeader} gutterBottom>
        {title}
      </Typography>
      <Divider light />
    </div>
  );
};

export default SubHeader;
