import React from "react";
import { Paper, List } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { EmptyList, Loading } from "src/apps/commons/dataDisplays";

interface StyleProps {
  searchHeight?: number;
}
const useStyles = makeStyles(theme => ({
  table: {
    overflowY: "auto",
    marginTop: theme.spacing(2),
    height: (props: StyleProps) =>
      `calc(100vh - ${125 + (props.searchHeight ?? 0)}px)`,
    backgroundColor: theme.palette.background.default
  }
}));

interface Props<T extends unknown> extends StyleProps {
  isLoading: boolean;
  datas: T[];
  render: (data: T, index: number) => React.ReactElement;
}

export default function ListProduct<T>(props: Props<T>) {
  const { isLoading, datas, render, ...styleProps } = props;
  const classes = useStyles(styleProps);

  return (
    <Paper className={classes.table}>
      {isLoading ? (
        <Loading key="loading" />
      ) : datas.length > 0 ? (
        <List>{datas.map((d, idx) => render(d, idx))}</List>
      ) : (
        <EmptyList key="emptyList" />
      )}
    </Paper>
  );
}
