import { IRemyndBrand } from "../remyndBrand/types";
import {
  IPartnerBrand,
  IRemyndBrandPartnerMap,
  PartnerBrandWithMapping
} from "./types";

const constants = {
  label: "Mapping แบรนด์สินค้า"
};

const paths = {
  index: "/remyndBrandPartnerMap"
};

export { constants, paths };

// ---- Sorting --------------------------------------------------------------

export type BrandSortColumn = "nameTh" | "active" | "createdAt" | "updatedAt";

const selectorMap: Record<BrandSortColumn, (node: IPartnerBrand) => string> = {
  nameTh: node => node.nameTh,
  active: node => (node.active ? "1" : "0"),
  createdAt: node => node.createdAt,
  updatedAt: node => node.updatedAt
};

type Comparator = (a: IPartnerBrand, b: IPartnerBrand) => number;

export const sortList = (
  list: IPartnerBrand[],
  column: BrandSortColumn,
  desc: boolean
): IPartnerBrand[] => {
  const selector = selectorMap[column];
  const comparator: Comparator = (a, b) => {
    let val = selector(a).localeCompare(selector(b));
    if (val === 0) {
      val = a.nameTh.localeCompare(b.nameTh);
    }
    return desc ? -val : val;
  };
  return [...list].sort(comparator);
};

// ---- Convert / Filter -----------------------------------------------------

export const combineWithMappingData = (
  list: IPartnerBrand[],
  mappings: IRemyndBrandPartnerMap[]
): PartnerBrandWithMapping[] => {
  return list.map(it => {
    const mapping = mappings.find(m => m.partnerBrandId === it.id);
    const displayLabel = `${it.nameTh} (${it.nameEn})`;
    return {
      ...it,
      displayLabel: displayLabel,
      searchLabels: [displayLabel],
      mapping: mapping,
      mappedAt: mapping?.updatedAt
    };
  });
};

const isMatchActive = (item: PartnerBrandWithMapping, active?: boolean) => {
  return active == null || item.active === active;
};

const isMatchMapped = (item: PartnerBrandWithMapping, mapped?: boolean) => {
  return mapped == null || (item.mapping != null) === mapped;
};

const isMatchSearchTerm = (
  item: PartnerBrandWithMapping,
  searchTerm?: string
) => {
  const query = searchTerm?.toUpperCase();
  return !query || item.searchLabels.some(t => t.includes(query));
};

export const filterListItems = (
  items: PartnerBrandWithMapping[],
  searchTerm?: string,
  active?: boolean,
  mapped?: boolean
): PartnerBrandWithMapping[] => {
  // Filter by criteria
  const hasCriteria = active != null || mapped != null || searchTerm;
  if (hasCriteria) {
    return items.filter(item => {
      return (
        isMatchActive(item, active) &&
        isMatchMapped(item, mapped) &&
        isMatchSearchTerm(item, searchTerm)
      );
    });
  }
  return items;
};

export const convertListToDropdownOptions = (
  list: IRemyndBrand[]
): IDropdown<IRemyndBrand>[] => {
  return list.map(it => ({
    value: it.id,
    label: `${it.nameTh} (${it.nameEn})`,
    meta: it
  }));
};
