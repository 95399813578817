const constants = {
  label: "คู่มือ/ไฟล์แนบ"
};

const paths = {
  index: "/remyndManual",
  show: "/remyndManual/show",
  create: "/remyndManual/create",
  edit: "/remyndManual/edit"
};

export { constants, paths };
