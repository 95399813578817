const constants = {
  label: "แบรนด์สินค้า"
};

const paths = {
  index: "/remyndBrand",
  create: "/remyndBrand/create",
  edit: "/remyndBrand/edit"
};

export { constants, paths };
