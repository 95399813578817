const constants = {
  label: "สินค้า"
};

const paths = {
  index: "/remyndProduct",
  create: "/remyndProduct/create",
  edit: "/remyndProduct/edit"
};

export { constants, paths };
