import React from "react";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Typography,
  withStyles
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FilterListIcon from "@material-ui/icons/FilterList";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1)
  }
}));

const Accordion = withStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    minHeight: 72,
    "& > .MuiAccordionSummary-root": {
      minHeight: 72
    }
  }
}))(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  content: {
    "& > h2": {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main
    }
  }
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(1, 3, 3, 3)
  }
}))(MuiAccordionDetails);

// ---------------------------------------------------------------------------

type Props = {
  title: string;
  icon?: "info" | "edit" | "search" | "filter" | "settings";
  defaultCollapse?: boolean;
};

const CollapsiblePanel: React.FC<Props> = props => {
  const classes = useStyles();
  const { title, icon, children, defaultCollapse } = props;

  const [expanded, setExpanded] = React.useState(!defaultCollapse);
  const toggleExpanded = () => {
    setExpanded(e => !e);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={toggleExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`panelHeader-${title}`}
        >
          {icon ? (
            <Box ml={1} mr={2}>
              {icon === "edit" && <EditIcon color="primary" />}
              {icon === "info" && <InfoIcon color="primary" />}
              {icon === "search" && <SearchIcon color="primary" />}
              {icon === "filter" && <FilterListIcon color="primary" />}
              {icon === "settings" && <SettingsIcon color="primary" />}
            </Box>
          ) : (
            <Box ml={1} />
          )}
          <Typography variant="h2">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CollapsiblePanel;
