import { AnySchema, StringSchema } from "yup";
import { TransformFunction } from "yup/lib/types";

export const replaceEmptyString = (value?: string) => {
  if (value !== undefined && value.trim().length > 0) {
    return value;
  } else {
    return undefined;
  }
};

export const yupTrimToNull: TransformFunction<StringSchema> = (
  currentValue,
  _
) => {
  if (currentValue == null) return undefined;
  const trimmed = currentValue.trim();
  return trimmed.length > 0 ? trimmed : undefined;
};

export const yupParseNumber: TransformFunction<AnySchema> = (
  currentValue,
  _
) => {
  try {
    if (!currentValue && currentValue !== 0) return undefined;
    return Number(currentValue);
  } catch (_) {
    return undefined;
  }
};
