const constants = {
  label: "Remynd Admin User"
};

const paths = {
  index: "/adminUser",
  create: "/adminUser/create",
  edit: "/adminUser/edit",
  changePassword: "/adminUser/changePassword"
};

export { constants, paths };
