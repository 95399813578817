import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Hidden,
  Collapse,
  makeStyles
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { fade } from "@material-ui/core/styles/colorManipulator";
import menus, { MenuTypes } from "../menus";

const blackColor = "#000";
const whiteColor = "#FFF";
const drawerWidth = 260;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const boxShadow = {
  boxShadow: `0 10px 30px -12px ${fade(blackColor, 0.42)}, 
    0 4px 25px 0px ${fade(blackColor, 0.12)},
    0 8px 10px -5px ${fade(blackColor, 0.2)}`
};

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    backgroundColor: "#81abd8",
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%"
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "auto",
      left: "0",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition
    }
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  aLink: {
    textDecoration: "none"
  },
  itemLink: {
    width: "auto",
    margin: "10px 10px 5px 10px",
    transition: "all 300ms linear",
    borderRadius: "4px",
    position: "relative",
    padding: "10px 15px",
    backgroundColor: "transparent"
  },
  subItemLink: {
    width: "auto",
    margin: "0px 10px 0px 10px",
    padding: "10px 10px 10px 40px",
    transition: "all 300ms linear",
    borderRadius: "4px",
    position: "relative",
    backgroundColor: "transparent"
  },
  active: {
    backgroundColor: "#366EAB",
    boxShadow: `0 12px 20px -10px ${fade("#366EAB", 0.28)},
    0 4px 20px 0 ${fade(blackColor, 0.12)}, 
    0 7px 8px -5px ${fade("#366EAB", 0.2)}`,
    "&:hover,&:focus": {
      backgroundColor: "#366EAB",
      boxShadow: `0 12px 20px -10px ${fade("#366EAB", 0.28)}, 
      0 4px 20px 0 ${fade(blackColor, 0.12)}, 
      0 7px 8px -5px ${fade("#366EAB", 0.2)}`
    }
  },
  listItemIcon: {
    minWidth: "42px"
  },
  itemIcon: {
    minWidth: "30px",
    fontWeight: "bold",
    color: whiteColor
  },
  subItemIcon: {
    minWidth: "30px",
    color: whiteColor
  },
  logo: {
    position: "relative",
    height: "75px",
    padding: "15px 15px",
    // backgroundColor: whiteColor,
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",
      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "#E0EAF5"
    }
  },
  logoImage: {
    width: "30px",
    display: "inline-block",
    maxHeight: "30px",
    marginLeft: "10px",
    marginRight: "15px"
  },
  mainImage: {
    top: "18px",
    left: "22px",
    position: "absolute",
    fontSize: "1.85rem",
    color: whiteColor,
    fontWeight: 600
  },
  subImage: {
    width: "180px",
    top: "10px",
    left: "36px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0",
    filter: "grayscale(20%)"
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    width: "260px",
    overflowScrolling: "touch"
  },
  itemText: {
    margin: "0",
    lineHeight: "20px",
    fontSize: "0.9rem",
    color: whiteColor
  },
  subItemText: {
    margin: "0",
    lineHeight: "20px",
    fontSize: "0.9rem",
    color: fade(whiteColor, 0.8)
  }
}));

interface SideBarProps {
  handleDrawerToggle: () => void;
  open: boolean;
}

const Menu = (props: MenuTypes) => {
  const classes = useStyles();
  const router = useRouter();
  const [openMenu, setMenu] = React.useState(true);
  const handleToggleMenu = () => {
    setMenu(!openMenu);
  };

  const { name, path, subPaths } = props;
  const subMenus = props.subMenus ?? [];
  const isNested = subMenus.length > 0;

  if (!isNested && path) {
    const isActive =
      router.pathname === path ||
      (subPaths && subPaths.includes(router.pathname));

    return (
      <Link href={path}>
        <a className={classes.aLink}>
          <ListItem
            button
            className={`${classes.itemLink} ${isActive ? classes.active : ""}`}
          >
            {props.icon && (
              <ListItemIcon className={classes.listItemIcon}>
                <props.icon className={classes.itemIcon} />
              </ListItemIcon>
            )}

            <ListItemText
              primary={name}
              className={classes.itemText}
              disableTypography={true}
            />
          </ListItem>
        </a>
      </Link>
    );
  } else {
    return (
      <>
        <ListItem
          button
          className={classes.itemLink}
          onClick={handleToggleMenu}
        >
          {props.icon && (
            <ListItemIcon className={classes.listItemIcon}>
              <props.icon className={classes.itemIcon} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={name}
            className={classes.itemText}
            disableTypography={true}
          />
          {openMenu ? (
            <ExpandLessIcon className={classes.itemIcon} />
          ) : (
            <ExpandMoreIcon className={classes.itemIcon} />
          )}
        </ListItem>
        <Collapse in={openMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding style={{ marginBottom: 8 }}>
            {subMenus.map((subMenu, key) => {
              const isActive = router.pathname === subMenu.path;
              return (
                <Link href={subMenu.path} key={`subMenu_${key}`}>
                  <a className={classes.aLink}>
                    <ListItem
                      button
                      className={`${classes.subItemLink} ${
                        isActive ? classes.active : ""
                      }`}
                    >
                      {subMenu.icon && (
                        <ListItemIcon className={classes.listItemIcon}>
                          <subMenu.icon className={classes.itemIcon} />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={subMenu.render ?? subMenu.name}
                        className={classes.subItemText}
                        disableTypography={true}
                      />
                    </ListItem>
                  </a>
                </Link>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }
};

const SideBar = (props: SideBarProps) => {
  const classes = useStyles();
  const links = (
    <List className={classes.list}>
      {menus.map((menu, key) => (
        <Menu key={`menu_${key}`} {...menu} />
      ))}
    </List>
  );

  const brand = (
    <div className={classes.logo}>
      <div className={classes.logoImage}>
        <span className={classes.mainImage}>Remynd Admin</span>
      </div>
    </div>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default SideBar;
