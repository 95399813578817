import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { CircularProgress } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  title: string;
  description?: string;
  descriptionBuilder?: () => React.ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  confirming?: boolean;
}

const ConfirmDialog: React.FunctionComponent<Props> = props => {
  const {
    title,
    description,
    descriptionBuilder,
    open,
    onClose,
    onConfirm,
    confirming
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        disableBackdropClick={confirming}
        disableEscapeKeyDown={confirming}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        {(description || descriptionBuilder) && (
          <DialogContent key="confirmDialog-description">
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {descriptionBuilder ? descriptionBuilder() : description}
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={onClose} disabled={confirming} color="primary">
            ยกเลิก
          </Button>
          {onConfirm && (
            <Button
              key="confirmDialog-confirm"
              onClick={onConfirm}
              disabled={confirming}
              startIcon={
                confirming ? (
                  <CircularProgress color="secondary" size={20} />
                ) : undefined
              }
              color="primary"
            >
              ยืนยัน
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
