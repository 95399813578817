import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = makeStyles(_ => ({
  container: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center"
  }
}));

const PartnerUserTimestamp: React.FunctionComponent<unknown> = _ => {
  const classes = styles();
  return (
    <div className={classes.container}>
      <CircularProgress />
    </div>
  );
};

export default PartnerUserTimestamp;
