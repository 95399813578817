import React from "react";
import clsx from "clsx";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentLabel: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      textAlign: "right"
    },
    contentDesc: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.primary
    },
    noContent: {
      color: theme.palette.text.secondary
    }
  })
);

type Props = {
  label: React.ReactNode;
  value?: React.ReactNode;
  noWrap?: boolean;
};

const ShowValue = (props: Props) => {
  const classes = useStyles();
  const { label, value, noWrap } = props;

  const useTypography =
    !value || typeof value === "string" || typeof value === "number";

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography className={classes.contentLabel}>{label}</Typography>
      </Grid>
      <Grid item xs={8} zeroMinWidth={noWrap}>
        {useTypography ? (
          <Typography
            noWrap={noWrap}
            className={clsx(
              classes.contentDesc,
              value == null && classes.noContent
            )}
          >
            {value ?? "ไม่ระบุ"}
          </Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>
  );
};

export default ShowValue;
