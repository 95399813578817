import { IconButton, Tooltip } from "@material-ui/core";
import {
  Add as AddIcon,
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  SvgIconComponent
} from "@material-ui/icons";
import React from "react";

type TableButtonType =
  | "add"
  | "edit"
  | "delete"
  | "up"
  | "down"
  | "copy"
  | "check";

const iconMap: { [key in TableButtonType]: SvgIconComponent } = {
  add: AddIcon,
  edit: EditIcon,
  delete: DeleteIcon,
  up: ArrowUpwardIcon,
  down: ArrowDownwardIcon,
  copy: FileCopyIcon,
  check: CheckIcon
};

const tooltipMap: { [key in TableButtonType]?: string } = {
  add: "เพิ่ม",
  edit: "แก้ไข",
  delete: "ลบ",
  up: "เลื่อนขึ้น",
  down: "เลื่อนลง",
  copy: "คัดลอก"
};

const TableButton = (props: {
  type: TableButtonType;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
}) => {
  const { type, onClick, disabled, tooltip, style } = props;
  const iconComponent = iconMap[type];
  const button = (
    <IconButton
      aria-label={type}
      onClick={onClick}
      disabled={disabled}
      style={style}
      size="small"
    >
      {React.createElement(iconComponent)}
    </IconButton>
  );

  const tooltipText = tooltip || tooltipMap[type];
  if (disabled || !tooltipText) return button;
  return (
    <Tooltip title={tooltipText} placement="top">
      {button}
    </Tooltip>
  );
};

export default TableButton;
