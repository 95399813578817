import React, { useState } from "react";
import clsx from "clsx";
import {
  Avatar,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  makeStyles
} from "@material-ui/core";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles(_ => ({
  container: {
    marginBottom: "-34px"
  },
  labelContainer: {
    backgroundColor: "#00000087",
    color: "#ffffff",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    bottom: "40px",
    paddingLeft: "16px",
    borderRadius: "0 0 4px 4px"
  },
  label: {
    display: "inline-flex"
  },
  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "120px",
    margin: "auto",
    borderRadius: "4px"
    // border: "1px solid rgba(0, 0, 0, 0.23)"
  },
  actionContainer: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  actionIcon: {
    color: "#ffffff"
  },
  modalImage: {
    maxWidth: "800px"
  }
}));

interface Props {
  label: string;
  imageSrc: string;
  fileName?: string;
  className?: string;
}
interface StateProps {
  openModal: boolean;
}

const Image = (props: Props) => {
  const { label, imageSrc, fileName, className } = props;
  const classes = useStyles();

  const [state, setState] = useState<StateProps>({ openModal: false });

  const handleOpenModal = () => setState({ openModal: true });
  const handleCloseModal = () => setState({ openModal: false });

  return (
    <div className={clsx(classes.container, className)}>
      <Avatar variant="square" src={imageSrc} className={classes.image} />
      <div className={classes.labelContainer}>
        <Typography
          component="span"
          variant="subtitle1"
          className={classes.label}
        >
          {label}
        </Typography>
        <div className={classes.actionContainer}>
          <IconButton onClick={handleOpenModal}>
            <VisibilityIcon className={classes.actionIcon} />
          </IconButton>

          <IconButton href={imageSrc} download={fileName ? fileName : true}>
            <CloudDownloadIcon className={classes.actionIcon} />
          </IconButton>
        </div>
      </div>
      <Dialog maxWidth="xl" open={state.openModal} onClose={handleCloseModal}>
        <DialogContent>
          <img alt={label} src={imageSrc} className={classes.modalImage} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Image;
