import {
  AccountTree as AccountTreeIcon,
  Apple as AppleIcon,
  Backup as BackupIcon,
  Category as CategoryIcon,
  CategoryOutlined as CategoryOutlinedIcon,
  Dashboard as DashboardIcon,
  Description as DescriptionIcon,
  Devices as DevicesIcon,
  Face as FaceIcon,
  FileCopy as FileCopyIcon,
  Label as LabelIcon,
  SupervisorAccount as SupervisorAccountIcon,
  VerifiedUser as VerifiedUserIcon,
  VerticalSplit as VerticalSplitIcon,
  WidgetsOutlined as WidgetsOutlinedIcon
} from "@material-ui/icons";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import {
  constants as userProductConstants,
  paths as userProductPaths
} from "src/apps/modules/userProduct/utils";
import {
  constants as remyndBrandConstants,
  paths as remyndBrandPaths
} from "src/apps/modules/remyndBrand/utils";
import {
  constants as remyndBrandPartnerMapConstants,
  paths as remyndBrandPartnerMapPaths
} from "src/apps/modules/remyndBrandPartnerMap/utils";
import {
  constants as remyndCategoryConstants,
  paths as remyndCategoryPaths
} from "src/apps/modules/remyndCategory/utils";
import {
  constants as remyndCategoryPartnerMapConstants,
  paths as remyndCategoryPartnerMapPaths
} from "src/apps/modules/remyndCategoryPartnerMap/utils";
import {
  constants as remyndProductConstants,
  paths as remyndProductPaths
} from "src/apps/modules/remyndProduct/utils";
import {
  constants as remyndManualConstants,
  paths as remyndManualPaths
} from "src/apps/modules/remyndManual/utils";
import {
  constants as remyndScrapeCategoryConstants,
  paths as remyndScrapeCategoryPaths
} from "src/apps/modules/remyndScrapeCategory/utils";
import {
  constants as remyndWarrantyConstants,
  paths as remyndWarrantyPaths
} from "src/apps/modules/remyndWarranty/utils";
import {
  constants as uploadDataConstants,
  paths as uploadDataPaths
} from "src/apps/modules/uploadData/utils";
import {
  constants as adminUserConstants,
  paths as adminUserPaths
} from "src/apps/modules/adminUser/utils";
import {
  constants as partnerUserConstants,
  paths as partnerUserPaths
} from "src/apps/modules/partnerUser/utils";

interface CommonMenuType {
  name: string | JSX.Element;
  render?: JSX.Element;
  icon?: OverridableComponent<SvgIconTypeMap<unknown, "svg">>;
  path?: string;
  subPaths?: string[];
}

export interface MenuTypes extends CommonMenuType {
  subMenus?: SubMenuTypes[];
}

interface SubMenuTypes extends CommonMenuType {
  path: string;
}

const menus: MenuTypes[] = [
  {
    name: "Dashboard",
    icon: DashboardIcon,
    path: "/"
  },
  {
    name: userProductConstants.label,
    icon: WidgetsOutlinedIcon,
    path: userProductPaths.index,
    subPaths: [userProductPaths.edit]
  },
  {
    name: "จัดการข้อมูล Master",
    icon: VerticalSplitIcon,
    subMenus: [
      {
        name: remyndBrandConstants.label,
        icon: AppleIcon,
        path: remyndBrandPaths.index,
        subPaths: [remyndBrandPaths.create, remyndBrandPaths.edit]
      },
      {
        name: remyndCategoryConstants.label,
        icon: CategoryIcon,
        path: remyndCategoryPaths.index,
        subPaths: []
      },
      {
        name: remyndProductConstants.label,
        icon: DevicesIcon,
        path: remyndProductPaths.index,
        subPaths: [remyndProductPaths.create, remyndProductPaths.edit]
      },
      {
        name: remyndManualConstants.label,
        icon: DescriptionIcon,
        path: remyndManualPaths.index,
        subPaths: [
          remyndManualPaths.create,
          remyndManualPaths.edit,
          remyndManualPaths.show
        ]
      },
      {
        name: remyndWarrantyConstants.label,
        icon: VerifiedUserIcon,
        path: remyndWarrantyPaths.index,
        subPaths: [remyndWarrantyPaths.create, remyndWarrantyPaths.edit]
      }
    ]
  },
  {
    name: "Mapping ข้อมูล",
    icon: AccountTreeIcon,
    subMenus: [
      {
        name: remyndBrandPartnerMapConstants.label,
        icon: AppleIcon,
        path: remyndBrandPartnerMapPaths.index,
        subPaths: []
      },
      {
        name: remyndCategoryPartnerMapConstants.label,
        icon: CategoryIcon,
        path: remyndCategoryPartnerMapPaths.index,
        subPaths: []
      },
      {
        name: remyndScrapeCategoryConstants.label,
        icon: CategoryOutlinedIcon,
        path: remyndScrapeCategoryPaths.index,
        subPaths: []
      }
    ]
  },
  {
    name: "Upload ข้อมูล",
    icon: BackupIcon,
    subMenus: [
      {
        name: uploadDataConstants.sswLabel,
        icon: FileCopyIcon,
        path: uploadDataPaths.ssw,
        subPaths: []
      },
      {
        name: uploadDataConstants.serialNoLabel,
        icon: LabelIcon,
        path: uploadDataPaths.serialNo,
        subPaths: []
      }
    ]
  },
  {
    name: adminUserConstants.label,
    icon: SupervisorAccountIcon,
    path: adminUserPaths.index,
    subPaths: [
      adminUserPaths.create,
      adminUserPaths.edit,
      adminUserPaths.changePassword
    ]
  },
  {
    name: partnerUserConstants.label,
    icon: FaceIcon,
    path: partnerUserPaths.index,
    subPaths: [
      partnerUserPaths.create,
      partnerUserPaths.edit,
      partnerUserPaths.changePassword
    ]
  }
];

export default menus;
