const constants = {
  sswLabel: "Upload สินค้า SSW",
  serialNoLabel: "Import Serial No."
};

const paths = {
  ssw: "/uploadData/ssw",
  serialNo: "/uploadData/serialNo"
};

export { constants, paths };
