const constants = {
  label: "Remynd Partner User"
};

const paths = {
  index: "/partnerUser",
  create: "/partnerUser/create",
  edit: "/partnerUser/edit",
  changePassword: "/partnerUser/changePassword"
};

export { constants, paths };
